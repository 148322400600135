import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth } from '../firebase';
import { getUserDocument } from '../services/user';

type AuthContextType = {
  user: {
    uid: string;
    email: string; 
    gender: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    photoURL: string;
  } | null
  loading: boolean
  syncing: boolean
  setUser?: (user) => void
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  syncing: false,
});
export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  let moonlight = null;
  let cachedUser = null;
  if (typeof window !== "undefined") { 
    moonlight = sessionStorage.getItem('moonlight') ? JSON.parse(sessionStorage.getItem('moonlight')) : null;
    cachedUser = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
  }

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(cachedUser ? false : true);
  const [syncing, setSyncing] = useState(false);

  useEffect(() => {
    // this runs in a useEffect to prevent a hydration mismatch
    setUser(cachedUser);
    let unsubscribe;

    // subscribe to auth observer
    getAuth().then(({ auth, onAuthStateChanged }) => {

      // onAuthStateChanged returns an unsubscribe function
      unsubscribe = onAuthStateChanged(auth, async (newUser) => {
        console.log('auth state change', newUser, auth?.currentUser?.email)
        let uid = newUser?.uid;
        if (moonlight) {
          console.log('Moonlighting', moonlight);
          uid = moonlight.uid;
        }
        if (newUser === null) {
          setUser(null);
        } else {
          if (uid !== cachedUser?.uid) {
            setSyncing(true);
          }

          const userDoc = await getUserDocument(uid);
          sessionStorage.setItem('user', JSON.stringify(userDoc));
          setUser(userDoc);
          setSyncing(false);

          // fire Klaviyo identify event - this fires on every auth state change
          // which is a pain, but we need to implement a cached login before we 
          // can do anything about it
          if (typeof klaviyo !== 'undefined' && !moonlight) {
            klaviyo.identify({
              'email' : userDoc.email,
              'first_name' : userDoc.firstName,
              'last_name' : userDoc.lastName,
            });
          }



        }
  
        setLoading(false);
      });
    });
     
    // unsubscribe from auth observer on unmount
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading, syncing, setUser }}>
      { children }
    </AuthContext.Provider>
  );
}
